:root {
  --font-sans: 'Inter', --apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --app-bg: var(--gray1);
  --cmdk-shadow: 0 16px 70px rgb(0 0 0 / 20%);

  --lowContrast: #ffffff;
  --highContrast: #000000;

  --gray1: hsl(0, 0%, 99%);
  --gray2: hsl(0, 0%, 97.3%);
  --gray3: hsl(0, 0%, 95.1%);
  --gray4: hsl(0, 0%, 93%);
  --gray5: hsl(0, 0%, 90.9%);
  --gray6: hsl(0, 0%, 88.7%);
  --gray7: hsl(0, 0%, 85.8%);
  --gray8: hsl(0, 0%, 78%);
  --gray9: hsl(0, 0%, 56.1%);
  --gray10: hsl(0, 0%, 52.3%);
  --gray11: hsl(0, 0%, 43.5%);
  --gray12: hsl(0, 0%, 9%);

  --grayA1: hsla(0, 0%, 0%, 0.012);
  --grayA2: hsla(0, 0%, 0%, 0.027);
  --grayA3: hsla(0, 0%, 0%, 0.047);
  --grayA4: hsla(0, 0%, 0%, 0.071);
  --grayA5: hsla(0, 0%, 0%, 0.09);
  --grayA6: hsla(0, 0%, 0%, 0.114);
  --grayA7: hsla(0, 0%, 0%, 0.141);
  --grayA8: hsla(0, 0%, 0%, 0.22);
  --grayA9: hsla(0, 0%, 0%, 0.439);
  --grayA10: hsla(0, 0%, 0%, 0.478);
  --grayA11: hsla(0, 0%, 0%, 0.565);
  --grayA12: hsla(0, 0%, 0%, 0.91);

  --blue1: hsl(206, 100%, 99.2%);
  --blue2: hsl(210, 100%, 98%);
  --blue3: hsl(209, 100%, 96.5%);
  --blue4: hsl(210, 98.8%, 94%);
  --blue5: hsl(209, 95%, 90.1%);
  --blue6: hsl(209, 81.2%, 84.5%);
  --blue7: hsl(208, 77.5%, 76.9%);
  --blue8: hsl(206, 81.9%, 65.3%);
  --blue9: hsl(206, 100%, 50%);
  --blue10: hsl(208, 100%, 47.3%);
  --blue11: hsl(211, 100%, 43.2%);
  --blue12: hsl(211, 100%, 15%);
}
.ais-Highlight {
  color: var(--blue9);
  font-weight: 600;
}
.konta-cmdk {
  .overflow-hidden {
    overflow: hidden;
  }
  .information-text {
    font-size: 12px;
    height: 22px;
    padding: 0 0 8px 8px;
    color: var(--gray9);
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  [cmdk-root] {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 8px 8px 8px;
    background: #ffffff;
    border-radius: 12px;
    font-family: var(--font-sans);
    border: 1px solid var(--gray6);
    box-shadow: var(--cmdk-shadow);
    transition: transform 100ms ease;

    .dark & {
      background: rgba(22, 22, 22, 0.7);
    }
  }

  [cmdk-input] {
    font-family: var(--font-sans);
    border: none;
    width: 100%;
    font-size: 17px;
    padding: 8px 8px 16px 8px;
    outline: none;
    background: var(--bg);
    color: var(--gray12);
    border-bottom: 1px solid var(--gray6);
    margin-bottom: 12px;
    border-radius: 0;

    &::placeholder {
      color: var(--gray9);
    }
  }

  [cmdk-badge] {
    height: 20px;
    background: var(--grayA3);
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    font-size: 12px;
    color: var(--grayA11);
    border-radius: 4px;
    margin: 4px 0 4px 4px;
    user-select: none;
    text-transform: capitalize;
    font-weight: 500;
  }

  [cmdk-item] {
    content-visibility: auto;
    cursor: pointer;
    height: 48px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    color: var(--gray11);
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;

    &.help-center-item {
      height: fit-content;
      padding: 10px 16px 10px 16px;
    }

    &[data-selected='true'] {
      background: var(--grayA3);
      color: var(--gray12);
    }

    &[data-disabled='true'] {
      color: var(--gray8);
      cursor: not-allowed;
    }

    &:active {
      transition-property: background;
      background: var(--gray4);
    }

    & + [cmdk-item] {
      margin-top: 4px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  [cmdk-list] {
    height: min(330px, calc(var(--cmdk-list-height)));
    max-height: 400px;
    overflow: auto;
    overscroll-behavior: contain;
    transition: 100ms ease;
    transition-property: height;
  }

  [cmdk-separator] {
    height: 1px;
    width: 100%;
    background: var(--gray5);
    margin: 4px 0;
  }

  *:not([hidden]) + [cmdk-group] {
    margin-top: 8px;
  }

  [cmdk-group-heading] {
    user-select: none;
    font-size: 12px;
    color: var(--gray11);
    padding: 0 8px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .cmdk-empty {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    color: var(--gray11);
    flex-direction: column;
    gap: 2px;
  }
  [cmdk-empty] {
    font-size: 14px;
    white-space: pre-wrap;
    color: var(--gray11);
  }
}
